import style from './SelectInput.module.scss'

export default function CustomSelect({ name, label, options, change }) {
    return (
        <div className={style.container}>
            <label>{label}</label>
            <select onChange={(e) => change(e.target.value)} className={style.select} name={name}>
                {
                    options.map((item, i) => {
                        return <option className={style.option} value={item}>{item}</option>
                    })
                }
            </select>
        </div>
    )
}
