import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const ContactSubmissionContext = createContext();

export const useContactSubmissions = () => useContext(ContactSubmissionContext);

export const ContactSubmissionProvider = ({ children }) => {
    const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
    const [contactSubmissions, setContactSubmissions] = useState([]);

    const fetchContactSubmissions = async () => {
        try {
            if (isAuthenticated && user['digitalbyte.io_cleared']) {
                const accessToken = await getAccessTokenSilently();
        
                const r = await axios.get(`${process.env.REACT_APP_API_URL}/forms`, {
                    headers: {
                        authorization: `Bearer ${accessToken}`
                    }
                })
                console.log(r.data)
                setContactSubmissions(r.data); 
            }
        } catch (error) {
                console.error('Error fetching contact submissions:', error);
                setContactSubmissions([]);
            }
        };

    useEffect(() => {
        fetchContactSubmissions();
    }, [user]);

  return (
    <ContactSubmissionContext.Provider value={{ contactSubmissions, fetchContactSubmissions }}>
      {children}
    </ContactSubmissionContext.Provider>
  );
};
