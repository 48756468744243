import React, { useContext, useState } from 'react'
import Modal from '@mui/material/Modal';
import style from './ContactItem.module.scss';
import { Icon, IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from '../Providers/SnackbarProvider';
import { useContactSubmissions } from '../Providers/ContactProvider';

export default function ContactItem(item) {
    const { openSnackbar } = useSnackbar();
    const { fetchContactSubmissions } = useContactSubmissions();
    const { full_name, email, phone, proj_desc, createdat } = item.item;
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const [open, setOpen] = useState();
    const [confirmation, setConfirmation] = useState();

    function displayDate(t) {
        const date = new Date();
        return date.toLocaleDateString();
    }

    async function archiveSubmission(id) {
        try {
            if (isAuthenticated && user['digitalbyte.io_cleared']) {
                const accessToken = await getAccessTokenSilently();

                const r = await axios.post(`${process.env.REACT_APP_API_URL}/forms/delete`, {id: id.toString()}, {
                    headers: {
                        authorization: `Bearer ${accessToken}`
                    }
                })
                if (r.status == 200) {
                    openSnackbar(`Submission from ${full_name} has been archived!`, 'success');
                    fetchContactSubmissions()
                } else {
                    fetchContactSubmissions()
                    openSnackbar(`An error has occured.`, 'error');
                }
            } 
        } catch (err) {
            console.log(err);
        }
    }

    function archive() {
        setConfirmation(false);
        archiveSubmission(item.item.id);
    }

    return (
        <div className={style.container}>
            <div className={style.subContainer}>
                <p className={style.fname}>{full_name}</p>
                <div className={style.right}>
                    <p  className={style.date}>{displayDate(createdat)}</p>
                    <span className={style.pipe}>|</span>
                    <div className={style.imageContainer}>
                        <Tooltip title='View Submission' placement='top'>
                            <IconButton>
                                <img className={style.img} onClick={() => setOpen(true)}  src='https://res.cloudinary.com/dam8havko/image/upload/v1720843908/7830824_view_icon_1_lug5re.svg' alt='View Submission' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Archive Submission' placement='top'>
                            <IconButton onClick={() => setConfirmation(true)}>
                                <img className={style.img} src='https://res.cloudinary.com/dam8havko/image/upload/v1720844107/326606_delete_icon_lumkvv.svg' alt='Delete Submission' />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Modal
                open={confirmation}
                onClose={() => setConfirmation(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={style.modalContainer}>
                    <p>Are you sure you want to archive this submission?</p>
                    <div className={style.btnContainer}>
                        <button className={style.cancelButton} onClick={() => setConfirmation(false)}>Cancel</button>
                        <button className={style.archiveButton} onClick={() => archive()}>Archive</button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={style.modalContainer}>
                    <h4 className={style.header}>Contact Submission</h4>
                    <p>Submitted: {displayDate(createdat)}</p>

                    <div className={style.fieldsContainer}>
                        <span className={style.itemTitle}>Name:</span>
                        <p className={style.field}>{full_name}</p>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <div className={style.fieldSubContainer}>
                                <span className={style.itemTitle}>Email:</span>
                                <p className={style.field}>{email}</p>
                            </div>
                            <div className={style.fieldSubContainer}>
                            <span className={style.itemTitle}>Phone:</span>
                                <p className={style.field}>{phone}</p>
                            </div>
                        </div>
                        <span className={style.itemTitle}>Message:</span>
                        <p className={style.field}>{proj_desc}</p>
                        <div className={style.btnGroup}>
                            <IconButton style={{ fontSize: '16px'}}>
                                <button className={style.button}>Mark Viewed</button>
                            </IconButton>
                            <IconButton style={{ fontSize: '16px' }}>
                                <button style={{ backgroundColor: '#635CEF'}} className={style.button}>Followed Up</button>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
