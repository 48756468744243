import { useState, useEffect } from 'react'
import Spacing from '../Spacing'
import style from './Requirement.module.scss'
import CustomInput from '../Inputs';
import DividerOne from '../Dividers/DividerOne';
import CustomSelect from '../Inputs/CustomSelect';

export default function Requirements({}) {
    const [ data, setData ] = useState({
        companyName: '',
        contactPerson: '',
        emailAddress: '',
        phone: '',
        address: '',
        projectName: '',
        briefDescription: '',
        launchDate: '',
        hasWebsite: 'Yes',
        siteURL: '',
        aboutCurrentWebsite: '',
        primaryGoals: '',
        targetAudience: '',
        mainGoals: '',
        designPreferences: '',
        exampleSite1: '',
        exampleSite2: '',
        exampleSite3: '',
        pages: '',
        existingContent: 'No',
        existingContentData: '',
        assistanceContentCreation: '',
        blogOrNews: '',
        specialFeatures: '',
        sellingProducts: '',
        productAmount: '',
        preferredPaymentGateway: '',
        specificEcommerceFeatures: '',
        seoRequirements: 'No',
        keywordResearch: 'No',
        socialMedia: 'No',
        emailMarketing: 'No',
        technicalRequirements: '',
        ongoingSupport: '',
        budget: '',
        timeline: '',
        additionalInformation: ''
    })

    useEffect(() => {
        console.log(data)
    }, [data])
    return (
        <div>
            <Spacing lg="70" md="70" />
            <Spacing lg="140" md="80" />
            <div className={style.container}>
                <h2>Requirements Form</h2>
                <div className={style.divider} />
                <p>This is the Digitalbyte requirements document. Please fill this out when directed, and ask your Digitalbyte representative if you have any questions.</p>
                <form>
                    <DividerOne title="Contact Information" />
                    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        <CustomInput
                            name='companyName'
                            label='Company Name'
                            value={data}
                            change={(e) => setData({...data, companyName: e})}
                            />
                        <CustomInput
                            name='contactPerson'
                            label='Contact Person'
                            value={data}
                            change={(e) => setData({...data, contactPerson: e})}
                            />
                    </div>
                    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        <CustomInput
                            name='emailAddress'
                            label='Email Address'
                            value={data}
                            change={(e) => setData({...data, emailAddress: e})}
                            />
                        <CustomInput
                            name='phoneNumber'
                            label='Phone Number'
                            value={data}
                            change={(e) => setData({...data, phone: e}) }
                            />
                    </div>
                    <CustomInput
                        name='address'
                        label='Address'
                        value={data}
                        change={(e) => setData({...data, address: e}) }
                        />
                    <DividerOne title="Project Overview" />
                    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        <CustomInput
                            name='projectName'
                            label='Project Name'
                            value={data}
                            change={(e) => setData({...data, projectName: e})}
                            />
                        <CustomInput
                            name='launchDate'
                            label='Desired Launch Date'
                            value={data}
                            change={(e) => setData({...data, launchDate: e})}
                            />
                    </div>
                    <CustomInput
                        name='briefDescription'
                        label='Brief Description'
                        value={data}
                        textArea={true}
                        change={(e) => setData({...data, briefDescription: e})}
                        />
                    <DividerOne title="Current Website" />
                    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        <CustomSelect
                            name='hasWebsite'
                            label='Do you have a current website?' 
                            options={['Yes', 'No']} 
                            change={(e) => setData({...data, currentWebsite: e})}
                            />
                        <CustomInput
                            name='siteURL'
                            label='If yes, what is the URL?'
                            value={data}
                            change={(e) => setData({...data, siteURL: e})}
                            />
                    </div>
                    <CustomInput 
                        name='aboutCurrentWebsite'
                        label='What do you like and dislike about your current website?'
                        value={data}
                        textArea={true}
                        change={(e) => setData({...data, aboutCurrentWebsite: e})}
                        />
                    <DividerOne title="Website Goals" />
                    <CustomInput 
                        name='primaryGoals'
                        label='What is the primary purpose of your website? (e.g., Informational, E-Commerce, Portfolio, Blog, etc.)'
                        value={data}
                        textArea={true}
                        change={(e) => setData({...data, aboutCurrentWebsite: e})}
                        />
                    <CustomInput 
                        name='targetAudience'
                        label='Who is your target audience? (e.g., Age, Gender, Demographic, Location, etc.)'
                        value={data}
                        textArea={true}
                        change={(e) => setData({...data, targetAudience: e})}
                        />
                    <CustomInput 
                        name='mainGoals'
                        label='What are the main goals you want to achieve with this website/application?'
                        value={data}
                        textArea={true}
                        change={(e) => setData({...data, mainGoals: e})}
                        />
                    <DividerOne title="Design Preferences" />
                    <CustomInput 
                        name='designPreferences'
                        label='Do you have any specific design preferences or themes in mind? (e.g., Colors, Layout, style)'
                        value={data}
                        textArea={true}
                        change={(e) => setData({...data, designPreferences: e})}
                        />
                    <CustomInput
                        name='exampleSite1'
                        label='Example site #1'
                        value={data}
                        change={(e) => setData({...data, exampleSite1: e})}
                        />
                    <CustomInput
                        name='exampleSite2'
                        label='Example site #2'
                        value={data}
                        change={(e) => setData({...data, exampleSite2: e})}
                        />
                    <CustomInput
                        name='exampleSite3'
                        label='Example site #3'
                        value={data}
                        change={(e) => setData({...data, exampleSite3: e})}
                        />
                    <label>If you have an existing branding or style guide please send it to us in an email at <span style={{ color: 'lightblue'}}>support@digitalbyte.io</span>.</label>
                    <DividerOne title="Site Content" />
                    <CustomInput 
                        name='pages'
                        label='What types of pages do you need? (e.g., Home, About, Services, Blog, Contact)'
                        value={data}
                        textArea={true}
                        change={(e) => setData({...data, pages: e})}
                        />
                    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        <CustomSelect
                            name='existingContent'
                            label='Do you have existing content that will be used?' 
                            options={['Yes', 'No']} 
                            change={(e) => setData({...data, existingContent: e})}
                            />
                        <CustomSelect
                            name='assistanceContentCreation'
                            label='Do you have existing content that will be used?' 
                            options={['Yes', 'No']} 
                            change={(e) => setData({...data, existingContent: e})}
                            />
                    </div>
                    <CustomInput 
                        name='existingContentData'
                        label='If you said (Yes) to existing content please provide the content or indicate if it needs to be updated.'
                        value={data}
                        textArea={true}
                        change={(e) => setData({...data, existingContentData: e})}
                        />
                    <CustomSelect
                        name='blogOrNews'
                        label='Do you need a blog or news section?' 
                        options={['Yes', 'No']} 
                        change={(e) => setData({...data, blogOrNews: e})}
                        />
                    <CustomInput 
                        name='specialFeatures'
                        label='Do you need any special functionality or features? (e.g., Forms, Galleries, Booking System)'
                        value={data}
                        textArea={true}
                        change={(e) => setData({...data, specialFeatures: e})}
                        />
                    <DividerOne title="E-Commerce Requirements (If applicable)" />
                    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        <CustomSelect
                            name='sellingProducts'
                            label='Will you be selling products/services online?' 
                            options={['Yes', 'No']} 
                            change={(e) => setData({...data, sellingProducts: e})}
                            />
                        <CustomInput 
                            name='productAmount'
                            label='If yes, how many products will you be offering?'
                            value={data}
                            change={(e) => setData({...data, productAmount: e})}
                            />
                    </div>
                    <CustomInput 
                        name='preferredPaymentGateway'
                        label='Do you have a preferred payment gateway? (e.g., Paypal, Stripe)'
                        value={data}
                        change={(e) => setData({...data, preferredPaymentGateway: e})}
                        />
                    <CustomInput
                        name='specificEcommerceFeatures'
                        label='Do you need any specific e-commerce features? (e.g., Inventory Management, Product Variations, Subscription Services)'
                        value={data}
                        textArea={true}
                        change={(e) => setData({...data, specificEcommerceFeatures: e})}
                        />
                    <DividerOne title="SEO & Marketing" />
                    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        <CustomSelect
                            name='seoRequirements'
                            label='Any specific SEO requirements?' 
                            options={['Yes', 'No']} 
                            change={(e) => setData({...data, seoRequirements: e})}
                            />
                        <CustomSelect
                            name='keywordResearch'
                            label='Need help with SEO and keyword research?' 
                            options={['Yes', 'No']} 
                            change={(e) => setData({...data, keywordResearch: e})}
                            />
                    </div>
                    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        <CustomSelect
                            name='socialMedia'
                            label='Existing social media profiles to integrate?' 
                            options={['Yes', 'No']} 
                            change={(e) => setData({...data, socialMedia: e})}
                            />
                        <CustomSelect
                            name='emailMarketing'
                            label='Do you need email marketing integration?' 
                            options={['Yes', 'No']} 
                            change={(e) => setData({...data, emailMarketing: e})}
                            />
                    </div>
                    <DividerOne title="Technical Requirements" />
                    <CustomInput
                        name='technicalRequirements'
                        label='Do you have any specific technical requirements or preferences? (e.g., Hosting, CMS, Security)'
                        value={data}
                        textArea={true}
                        change={(e) => setData({...data, technicalRequirements: e})}
                        />
                    <CustomSelect
                        name='ongoingSupport'
                        label='Do you need ongoing maintenance and support?' 
                        options={['Yes', 'No']} 
                        change={(e) => setData({...data, ongoingSupport: e})}
                        />
                    <DividerOne title="Budget & Timeline" />
                    <CustomInput 
                        name='budget'
                        label='What is your budget for this project?'
                        value={data}
                        change={(e) => setData({...data, budget: e})}
                        />
                    <CustomInput 
                        name='timeline'
                        label='What is your preferred timeline for project completion?'
                        value={data}
                        change={(e) => setData({...data, timeline: e})}
                        />
                    <DividerOne title="Additonal Information" />
                    <CustomInput
                        name='additionalInformation'
                        label='Please provide any additional information or requirements you think are important for us to know.'
                        value={data}
                        textArea={true}
                        change={(e) => setData({...data, additionalInformation: e})}
                        />
                    <label><em>Thank you for taking your time completing this form. This allows us to give you an accurate estimate/quote of how much it will cost to build your application. Your representative will reach out within three (3) business days.</em></label>
                    <button>Submit</button>
                </form>
            </div>
        </div>
    )
}