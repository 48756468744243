import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DropDown from './DropDown';
import { useAuth0 } from '@auth0/auth0-react';
import { IoIosArrowDown } from "react-icons/io";

export default function Header({
  logoUrl,
  colorVariant,
  cart,
}) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [open, setOpen] = useState(false)
  const { loginWithRedirect, user, isAuthenticated, logout } = useAuth0();
  useEffect(() => {
    console.log(user)
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [user]);
  return (
    <header
      className={`cs_site_header cs_style_1 cs_sticky_header ${
        colorVariant ? colorVariant : 'cs_primary_color'
      } ${isSticky ? 'cs_gescout_show' : ''}`}
    >
      <div className="cs_main_header">
        <div className="container">
          <div className="cs_main_header_in">
            <div className="cs_main_header_left">
              <Link className="cs_site_branding" to="/">
                <img src={logoUrl} alt="Logo" />
              </Link>
            </div>
            <div className="cs_main_header_center">
              <div className="cs_nav cs_medium cs_primary_font">
                <ul
                  className={`${
                    mobileToggle ? 'cs_nav_list cs_active' : 'cs_nav_list'
                  }`}
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to="/">About</Link>
                    <DropDown>
                      <ul>
                        <li><Link to='about'>About Us</Link></li>
                        <li><Link to='team'>Team</Link></li>
                      </ul>
                    </DropDown>
                  </li>
                  <li>
                    <Link to="service">Services</Link>
                  </li>
                  {/* <li>
                    <Link to="blog">Blog</Link>
                  </li> */}
                  <li>
                    <Link to="contact">Contact</Link>
                  </li>
                </ul>
                <span
                  className={
                    mobileToggle
                      ? 'cs_menu_toggle cs_teggle_active'
                      : 'cs_menu_toggle'
                  }
                  onClick={() => setMobileToggle(!mobileToggle)}
                >
                  <span></span>
                </span>
              </div>
            </div>
            <div className="cs_main_header_right">
              {
                isAuthenticated ? 
                  <li className='menu-item-has-children' style={{ padding: '10px 0', display: 'flex', alignItems: 'center', position: 'relative' }}>
                    <img style={{ borderRadius: '50%', height: '30px', marginRight: '.5rem'}} src={user.picture} alt={`${user.name} profile`} />
                    Hello, {user.given_name}!
                    <IoIosArrowDown style={{ marginLeft: '.5rem', color: '#5625D8', cursor: 'pointer' }} size={20} onClick={() => setOpen(!open)} />
                    {
                      open ? <div style={{ position: 'absolute', right: '0', top: '50px', backgroundColor: 'black', width: '200px', padding: '1rem', borderRadius: '12px' }}>
                        <ul style={{ listStyle: 'none', margin: '0', padding: '0', textAlign: 'right'}}>
                          <li><Link to="/dashboard/home">Dashboard</Link></li>
                          <div style={{ height: '2px', width: '100%', backgroundColor: '#5625D8', margin: '.5rem 0'}} />
                          <li style={{ cursor: 'pointer' }} onClick={() => logout()}>Logout</li>
                        </ul>
                      </div> : null
                    }
                    
                  </li> : 
                    <Link
                    onClick={() => loginWithRedirect()}
                    to="/"
                    className={`cs_btn cs_style_1  ${
                      colorVariant ? 'cs_btn_white' : ''
                    }`}
                  >
                    Login | Register
                  </Link>
              }
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
