import React from 'react'
import style from './CustomInput.module.scss'

export default function CustomInput({ name, label, textArea, value, change }) {
    return (
        <div className={style.container}>
            <label>{label}</label>
            {
                !textArea ? <input name={name} label={label} value={value.name} onChange={(e) => change(e.target.value)} /> : <textarea autoComplete='on' maxLength={500} minLength={1} rows={8} value={value.name} onChange={(e) => change(e.target.value)} />
            }
        </div>
    )
}