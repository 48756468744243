import { useAuth0 } from '@auth0/auth0-react'
import { IconButton } from '@mui/material';
import ToolTip from '@mui/material/Tooltip'
import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';

export default function DashboardLayout({ children, cleared }) {
  const { user, isAuthenticated } = useAuth0();

  return (
    <>
    <div style={{ height: '100vh', width: '100vw', backgroundColor: '#08101F', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
      {
        isAuthenticated ? 
        <>
          <div style={{ padding: '1rem', borderBottom: '1px solid #363636', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <img style={{ height: '50px', width: '350px'}} src="https://res.cloudinary.com/dam8havko/image/upload/v1713329483/Group_7_mqswod.svg" alt="Digitalbyte.io" />
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center'}}>
              <img style={{ position: 'absolute', top: '14px', left: '10px' }} src="https://res.cloudinary.com/dam8havko/image/upload/v1713846060/9035096_search_icon_1_wojron.svg" alt='Search box' />
              <input style={{ backgroundColor: '#4B4B4B', color: '#fff', outline: 'none', border: 'none', padding: '.5rem', width: '250px', borderRadius: '25px', paddingLeft: '2.5rem'}} placeholder='Search...' />
              <ToolTip title="Notifications">
                <IconButton>
                  <img style={{ margin: '0 1rem', height: '35px'}} src="https://res.cloudinary.com/dam8havko/image/upload/v1720585640/9035146_notifications_icon_p7ci7z.svg" alt="Notifications" />
                </IconButton>
              </ToolTip>
              <div style={{ display: 'flex',  alignItems: 'center', cursor: 'pointer' }}>
                <img src={user?.picture} alt={`${user.name} profile settings`} style={{ height: '35px', borderRadius: '50%', border: '2px solid #635CEF'}} />
                <img style={{ marginRight: '1rem', height: '20px', paddingLeft: '.25rem'}} src="https://res.cloudinary.com/dam8havko/image/upload/v1720585915/211687_down_arrow_icon_e4cf4e.svg" alt="Dropdown Settings" />
              </div>
            </div>
          </div>
          <div style={{ height: '100%', display: 'flex'}}>
            {/* Side Navigation */}
            <div style={{ height: '100%', maxWidth: '75px', borderRight: '1px solid #363636', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '1rem'}}>
              <ToolTip title="Home" placement='right'>
                <IconButton>
                  <Link to="/dashboard/home"><img src="https://res.cloudinary.com/dam8havko/image/upload/v1720586727/Layer_1_dfomdx.svg" alt="Home" /></Link>
                </IconButton>
              </ToolTip>
              <ToolTip title="Your Services" placement='right'>
                <IconButton>
                  <Link to="/dashboard/services"><img src="https://res.cloudinary.com/dam8havko/image/upload/v1720586923/Bold_ckxtn9.svg" alt="Home" /></Link>
                </IconButton>
              </ToolTip>
              {
                user['digitalbyte.io_cleared'] ? 
                <ToolTip title="(Admin) Outreach" placement='right'>
                  <IconButton>
                    <Link to="/dashboard/admin-outreach"><img src="https://res.cloudinary.com/dam8havko/image/upload/v1720587001/Frame_bllhc2.svg" alt="Home" /></Link>
                  </IconButton>
                </ToolTip> : null
              }
            </div>
            <div style={{ height: '100%', width: '100%', margin: '2rem' }}>
              {children}
            </div>
          </div>
        </> : <div style={{ display: 'flex', flexDirection: 'column', marginTop: '30%', alignItems: 'center' }}>
            <CircularProgress />
            <p>Authenticating user...</p>
          </div>
      }
    </div>
    </>
  )
}
