import React from 'react'
import DashboardLayout from './Layout'
import { useAuth0 } from '@auth0/auth0-react'

export default function DashboardHome() {
  const { user } = useAuth0();
  return (
    <DashboardLayout>
      <h3 style={{ color: '#fff', fontWeight: '300' }}>Welcome, {user?.name}!</h3>
    </DashboardLayout>
  )
}
