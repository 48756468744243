import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Pages/Home";
import ServicePage from "./components/Pages/ServicePage";
import AboutPage from "./components/Pages/AboutPage";
import BlogPage from "./components/Pages/BlogPage";
import BlogListPage from "./components/Pages/BlogListPage";
import BlogDetailsPage from "./components/Pages/BlogDetailsPage";
import TeamPage from "./components/Pages/TeamPage";
import TeamDetailsPage from "./components/Pages/TeamDetailsPage";
import ContactPage from "./components/Pages/ContactPage";
import Layout2 from "./components/Layout/Layout2";
import TechStartupPage from "./components/Pages/TechStartupPage";
import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage";
import ErrorPage from "./components/Pages/ErrorPage";
import WebDev from "./components/Pages/Services/WebDev";
import SoftwareDev from "./components/Pages/Services/SoftwareDev";
import MobileApps from "./components/Pages/Services/MobileApps";
import UIDesign from "./components/Pages/Services/UIDesign";
import SystemIntegration from "./components/Pages/Services/SystemIntegration";
import DashboardHome from "./components/Pages/Dashboard/Home";
import DashboardServices from "./components/Pages/Dashboard/Services";
import AdminOutreach from "./components/Pages/Dashboard/AdminOutreach";
import Requirements from "./components/Pages/Requirements";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<Layout darkMode />}>
        <Route index element={<Home darkMode />} />
        <Route path="about" element={<AboutPage darkMode />} />
        {/********************Service Pages*****************************************/}
        <Route path="service" element={<ServicePage />} />
        <Route
          path="service/:serviceDetailsId"
          element={<ServiceDetailsPage />}
        />
        <Route path="/service/web-dev" element={<WebDev />} />
        <Route path="/service/software-dev" element={<SoftwareDev />} />
        <Route path="/service/mobile-app" element={<MobileApps />} />
        <Route path="/service/ui-design" element={<UIDesign />} />
        <Route
          path="/service/systems-integration"
          element={<SystemIntegration />}
        />
        <Route path="/requirements/submission" element={<Requirements />} />

        {/*************************************************************************/}

        <Route path="blog" element={<BlogPage />} />
        <Route path="blog-list" element={<BlogListPage />} />
        <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
        {/* <Route path="portfolio" element={<PortfolioPage />} /> */}
        {/* <Route
          path="portfolio/:portfolioDetailsId"
          element={<PortfolioDetailsPage />}
        /> */}
        {/* <Route path="case-study-details" element={<CaseStudyDetailsPage />} /> */}

        {/********************Team Pages*****************************************/}
        <Route path="team" element={<TeamPage />} />
        <Route path="team/:teamDetailsId" element={<TeamDetailsPage />} />
        <Route path="contact" element={<ContactPage />} />
        {/* <Route path="jobs" element={<ContactJobPage />} /> */}
        {/***********************************************************************/}

        {/* <Route path="shop" element={<Shop />} />
        <Route path="shop/:productId" element={<ProductDetails />} />
        <Route path="shop/cart" element={<Cart />} />
        <Route path="shop/checkout" element={<Checkout />} />
        <Route path="shop/success" element={<Success />} />
        <Route path="shop/wishlist" element={<Wishlist />} /> */}
      </Route>
      {/********************Dashboard Pages**************************************/}
      <Route path="/dashboard/home" element={<DashboardHome />} />
      <Route path="/dashboard/services" element={<DashboardServices />} />
      <Route path="/dashboard/admin-outreach" element={<AdminOutreach />} />
      {/* Start Light Mode */}
      <Route path="/light/" element={<Layout />}>
        {/* <Route path="marketing-agency" element={<MarketingAgencyPage />} /> */}
        <Route path="about" element={<AboutPage />} />
        {/********************Service Pages*****************************************/}
        <Route path="service" element={<ServicePage />} />
        <Route
          path="service/:serviceDetailsId"
          element={<ServiceDetailsPage />}
        />
        <Route path="service/web-dev" element={<WebDev />} />
        <Route path="service/software-dev" element={<SoftwareDev />} />
        <Route path="service/mobile-app" element={<MobileApps />} />
        <Route path="service/ui-design" element={<UIDesign />} />
        <Route
          path="service/systems-integration"
          element={<SystemIntegration />}
        />
        {/*************************************************************************/}
        <Route path="blog" element={<BlogPage />} />
        <Route path="blog-list" element={<BlogListPage />} />
        <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
        {/* <Route path="portfolio" element={<PortfolioPage />} />
        <Route
          path="portfolio/:portfolioDetailsId"
          element={<PortfolioDetailsPage />}
        /> */}
        {/* <Route path="case-study-details" element={<CaseStudyDetailsPage />} /> */}
        <Route path="team" element={<TeamPage />} />
        <Route path="team/:teamDetailsId" element={<TeamDetailsPage />} />
        <Route path="contact" element={<ContactPage />} />
      </Route>
      <Route path="/light/" element={<Layout2 />}>
        <Route path="tech-startup" element={<TechStartupPage />} />
      </Route>
      {/* <Route path="/light/" element={<Layout3 />}>
        <Route path="shop" element={<Shop />} />
        <Route path="shop/:productId" element={<ProductDetails />} />
        <Route path="shop/cart" element={<Cart />} />
        <Route path="shop/checkout" element={<Checkout />} />
        <Route path="shop/success" element={<Success />} />
        <Route path="shop/wishlist" element={<Wishlist />} />
      </Route> */}
      {/* End Light Mode */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
