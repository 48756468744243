import React from 'react'
import DashboardLayout from './Layout'

export default function DashboardServices() {
  return (
    <DashboardLayout>
        <h3 style={{ color: '#fff', fontWeight: '300' }}>Your Services</h3>
    </DashboardLayout>
  )
}
