import React from 'react'
import DashboardLayout from './Layout'
import ContactItem from '../../Contatact/ContactItem';
import { useContactSubmissions } from '../../Providers/ContactProvider';

export default function AdminOutreach() {
    const { contactSubmissions } = useContactSubmissions();

    return (
        <DashboardLayout>
            <h3 style={{ color: '#fff', fontWeight: '300' }}>Contact Submissions</h3>
            <div style={{ width: '100%', display: 'flex', gap: '3rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}><div style={{ height: '15px', width: '15px', border: '2px solid #707BDD', marginRight: '.5rem'}} /><p style={{ margin: 0, color: '#fff'}}>Newest -&gt; Oldest</p></div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}><div style={{ height: '15px', width: '15px', border: '2px solid #707BDD', marginRight: '.5rem'}} /><p style={{ margin: 0, color: '#fff'}}>Oldest -&gt; Newest</p></div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}><div style={{ height: '15px', width: '15px', border: '2px solid #707BDD', marginRight: '.5rem'}} /><p style={{ margin: 0, color: '#fff'}}>Viewed</p></div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}><div style={{ height: '15px', width: '15px', border: '2px solid #707BDD', marginRight: '.5rem'}} /><p style={{ margin: 0, color: '#fff'}}>Viewed</p></div>
            </div>
            <div style={{ height: '2px', width: '100%', backgroundColor: '#635CEF' }} />
            <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', gap: '.5rem'}}>
                {
                    contactSubmissions.length > 0 ? contactSubmissions.map((item, i) => {
                        return <ContactItem key={`item-${i}`} item={item} />
                    }) : <p>No submissions have been submitted.</p>
                }
            </div>
        </DashboardLayout>
    )
}
