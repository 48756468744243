import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'slick-carousel/slick/slick.css';
import './sass/index.scss';
import { SnackbarProvider } from './components/Providers/SnackbarProvider';
import { ContactSubmissionProvider } from './components/Providers/ContactProvider';
import { ThemeProvider } from '@emotion/react';

export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#5625d8',
    },
    secondary: {
      main: '#f50057',
    },
    text: {
      primary: 'rgba(239,235,235,0.87)',
    },
  },
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain="dev-4fea6m1bhyef3zai.us.auth0.com"
        clientId="Vp9BvdZElORKhXyXUtfMPAu4AI8ZQIBV"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://digitalbyte-auth0-api"
        }}
      >
        <SnackbarProvider>
          <ContactSubmissionProvider>
            <ThemeProvider theme={themeOptions}>
              <App />
            </ThemeProvider>
          </ContactSubmissionProvider>
        </SnackbarProvider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
