import React from 'react'
import style from './DividerOne.module.scss'

export default function DividerOne({ title }) {
    return (
        <div className={style.container}>
            <div className={style.divider} />
            <h5>{title}</h5>
            <div className={style.divider} />
        </div>
    )
}
